import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar'

const Celiulioze = () => (
    <Layout>
      <Helmet>
          <title>Reagentai Popieriaus Pramonei</title>
      </Helmet>
      <Header
        styleName='header header-paper'
        title={<span className="text-header-title">Popieriaus pramonė</span>}
        />
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Reagentai celiuliozės ir popieriaus pramonei</div>
          <div className="content-text">UAB „Inžinerinė ekologija“ užsiima aukšto technologinio lygio sprendimų įgyvendinimu ir kokybiškų cheminių medžiagų tiekimu technologiniam procesui celiuliozės ir popieriaus pramonėje:</div>
          <ul>
              <li className="content-text">Sintetiniai koaguliantai ir flokuliantai, skirti sedimentacijai bei balinimui</li>
              <li className="content-text">Biocidai bei paviršiaus aktyvios medžiagos, skirtos išvengti biologinio apaugimo, puvimo procesų ir nemalonaus kvapo, popieriaus masės kokybės gerinimui, nepertraukiamo popieriaus gamybos mašinos darbui užtikrinti</li>
              <li className="content-text">Antiputintojai, drėkinamosios medžiagos ir nuovirų inhibitoriai</li>
              <li className="content-text">Plovimo priemonių koncentratai, skirti įrangos, dirbančios aukštoje temperatūroje valymui</li>
              <li className="content-text">Dozavimo įranga: dozavimo siurbliai, automatinio dozavimo įrengimai, skirti ir koaguliantų bei flokuliantų dozavimui</li>
          </ul>
        </div>
        <Sidebar
        analysis='Anketos'
        boiler='Boileriams'
        cooling='Aušinimui'
        ro='Osmosui'
        broshure='IN-ECO brošiura'
        certificates='Sertifikatai'
        />
      </div>
    </Layout>
)

export default Celiulioze